<template>
  <b-card-code title="">
    <b-row>
      <b-col cols="6">
        <!-- <p>{{ token }}</p> -->
        <!-- <p>{{ applicationOptions }}</p> -->
        <h3 class="text-primary">Edit Role</h3>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          @click="back()"
          size="sm"
        >
          Back
        </b-button>
      </b-col>
      <b-col cols="12">
        <!-- form -->
        <validation-observer ref="RoleAddForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="updateRole">
            <!-- Name -->
            <b-form-group label="Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <vue-select-sides
              type="mirror"
              v-model="selected"
              :list="permissions"
            ></vue-select-sides>
            <b-button class="mt-1" type="submit" variant="primary"  :disabled="invalid">
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vueSelectSides from "vue-select-sides-forked";
import { required } from "@validations";
import "/node_modules/vue-select-sides-forked/styles/themes/light.scss";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    vueSelectSides,
  },
  data() {
    return {
      name: "",
      permissions: [],
      permissions_o: [],
      selected: [],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      var self = this;
      this.$http({
        method: "GET",
        // headers: { Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,  "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/permissions",
      }).then((res) => {
        console.log(res);
        this.permissions_o = res.data;
        res.data.map(function (value, key) {
          let p = {
            value: res.data[key].id,
            label: res.data[key].name,
            codename: res.data[key].codename,
          };
          // console.log(res.data[key].profile.organization)
          self.permissions.push(p);
        });
        console.log("xx", self.permissions_o);
      });
      //Load role data
      var self = this;
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "user/groups/" +
          this.$route.params.id,
      }).then((res) => {
        self.name = res.data.name;
        res.data.permissions.map(function (value, key) {
          console.log(value);
          self.selected.push(value.id);
        });
        console.log("yy", self.selected);
      });
    },
    back() {
      this.$router.push("/ecaroles");
    },
    updateRole: function () {
      var self = this;
      this.$refs.RoleAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          let perm_data = [];
          this.selected.map(function (value, key) {
            console.log(value);
            perm_data.push(self.permissions_o.find((o) => o.id === value));
          });
          console.log("final", perm_data);
          // Remove null an dundefined --> Backward compatibility
          let temp = [];
          for (let i of perm_data) i && temp.push(i); // copy each non-empty value to the 'temp' array
          perm_data = temp;
          console.log("final after cleaning", perm_data);
          let data = {
            name: this.name,
            permissions: perm_data,
          };
          const options = {
            method: "PATCH",
            // headers: {  Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`, "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "user/groups/" +
              this.$route.params.id +
              "/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.id) {
              this.$router.push({ name: "ecaroles" });
            }
          });
        }
      });
    },
  },
};
</script>
<style >
.vss-list {
  max-height: 400px;
}
</style>
